/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import {
  ref,
  orderByChild,
  equalTo,
  query,
  onValue,
  Unsubscribe,
} from 'firebase/database';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { UserLayout } from '../..';
import { usePagination, useUser } from '../../../hooks';
import { database } from '../../../lib/firebase';

export default function Request() {
  const [user] = useUser();
  const [request, setRequest] = React.useState([]);
  const { currentPage, setCurrentPage, pageNumbers, items } =
    usePagination(request);

  React.useEffect(
    function syncRequest() {
      const _request = [];
      let unsubscribe: Unsubscribe;
      if (user?.idUser) {
        unsubscribe = onValue(
          query(
            ref(database, 'requets'),
            orderByChild('id_user'),
            equalTo(user?.idUser)
          ),
          (snapshot) => {
            console.log(snapshot.exists());
            console.log(snapshot.val());
            if (snapshot.exists()) {
              snapshot.forEach((child) => {
                _request.push({ ...child.val(), id: child.key });
              });
              setRequest(_request);
            }
          }
        );
      }
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    },
    [user?.idUser]
  );

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const { page } = e.currentTarget.dataset;
    document.getElementById(`${currentPage}`).classList.remove('active');
    document.getElementById(`${page}`).classList.add('active');
    setCurrentPage(Number(page));
  };

  const prevPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (currentPage !== 1) {
      e.preventDefault();
      document.getElementById(`${currentPage - 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (currentPage !== pageNumbers.length) {
      e.preventDefault();
      document.getElementById(`${currentPage + 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <UserLayout>
      {request && request.length > 0 && items && items.length > 0 ? (
        <>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card">
                <div className="card-content table-responsive table-full-width">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th key="date">
                          <p className="text-center">FECHA</p>
                        </th>
                        <th key="store">
                          <p className="text-center">TIENDA</p>
                        </th>
                        <th key="status">
                          <p className="text-center">ESTATUS</p>
                        </th>
                        <th key="number_bill">
                          <p className="text-center">NUMERO DE ORDEN</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((_request) => (
                        <tr key={_request.id}>
                          <td className="text-center">{_request.date}</td>
                          <td className="text-center">
                            {_request.store.toUpperCase()}
                          </td>
                          {_request.status === 'rechazado' ? (
                            <td
                              className="text-center"
                              style={{ color: 'red' }}
                            >
                              {_request.status.toUpperCase()}
                            </td>
                          ) : _request.status === 'procesando' ? (
                            <td
                              className="text-center"
                              style={{ color: 'grey' }}
                            >
                              {_request.status.toUpperCase()}
                            </td>
                          ) : (
                            <td
                              className="text-center"
                              style={{ color: 'green' }}
                            >
                              {_request.status.toUpperCase()}
                            </td>
                          )}
                          <td className="text-center">
                            {_request.number_bill}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="row justify-content-center">
              <div className="card-content">
                <ul className="pagination">
                  <li>
                    <a
                      role="button"
                      tabIndex={0}
                      onClick={prevPage}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i aria-hidden>
                        <FaArrowCircleLeft size={20} />
                      </i>
                    </a>
                  </li>
                  {pageNumbers.map((page) => (
                    <li
                      id={String(page)}
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                      role="button"
                      tabIndex={0}
                      key={page}
                      data-page={page}
                      onClick={handleClick}
                      onKeyUp={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <a>{page}</a>
                    </li>
                  ))}
                  <li>
                    <a
                      role="button"
                      tabIndex={0}
                      onClick={nextPage}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i aria-hidden>
                        <FaArrowCircleRight size={20} />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <h4 className="text-center">
                No hay ninguna solicitud registrada.
              </h4>
            </div>
          </div>
          <div className="row justify-content-center">
            <Link href="/user/exchange" legacyBehavior>
              <button
                className="btn btn-default"
                style={{ position: 'relative', left: '1.5%' }}
                type="button"
              >
                Realizar solicitud
              </button>
            </Link>
          </div>
        </div>
      )}
    </UserLayout>
  );
}
