export const Stores = [
  {
    id: 1,
    name: 'Arquiconstructores',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C Casa Mall. Los Naranjos',
  },
  {
    id: 2,
    name: 'Decoraciones 360',
    city: 'El Tigre',
    state: 'Anzoategui',
    address: 'Av. Jesus Subuero C.C Amarillo',
  },
  {
    id: 3,
    name: 'Porcelana La Pastora',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C.C.T Chuao',
  },
  {
    id: 4,
    name: 'Porcelana La Pastora',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Orinoco Las Mercedes',
  },
  {
    id: 5,
    name: 'Porcelana La Pastora',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Venezuela y Casanova, el Recreo',
  },
  {
    id: 6,
    name: 'Porcelana La Pastora',
    city: 'Caracas',
    state: 'Miranda',
    address: 'La Guaira, Los Corales.',
  },
  {
    id: 7,
    name: 'Dekasa Hogar',
    city: 'Maracaibo',
    state: 'Zulia',
    address: 'Av. 8 Santa Rita, Maracaibo',
  },
  {
    id: 8,
    name: 'Spazio Desing',
    city: 'Maracaibo',
    state: 'Zulia',
    address: 'Calle 79, Sector Belloso, Maracaibo',
  },
  {
    id: 9,
    name: 'Novalart',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Principal de Chuao',
  },
  {
    id: 10,
    name: 'Proyecto T18',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C.C.T Chuao',
  },
  {
    id: 11,
    name: 'La Porcelana',
    city: 'Puerto Ordaz',
    state: 'Bolivar',
    address: 'UD. 283, Parcelamiento Industrial',
  },
  {
    id: 12,
    name: 'Inova',
    city: 'Cagua',
    state: 'Aragua',
    address: 'Carretera Nacional Cagua, La Villa',
  },
  {
    id: 13,
    name: 'Inova',
    city: 'Maracay',
    state: 'Aragua',
    address: 'Av. Las Delicias C.C Oram V',
  },
  {
    id: 14,
    name: 'Tu Ceramica',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C Galeria Prados del Este',
  },
  {
    id: 15,
    name: 'Deko Styl',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C.C.T Chuao',
  },
  {
    id: 16,
    name: 'Arredo Xpress',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Calle Paris, Las Mercedes',
  },
  {
    id: 17,
    name: 'Porcelana La Pastora',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Venezuela y Casanova, el Recreo',
  },
  {
    id: 18,
    name: 'Himalaya',
    city: 'PTO La Cruz',
    state: 'Anzoategui',
    address: 'Av. Jorge Rodriguez',
  },
  {
    id: 19,
    name: 'Centro Homy',
    city: 'Caracas',
    state: 'Miranda',
    address: 'C.C Santa Fe, Baruta',
  },
  {
    id: 20,
    name: 'Euro Baños',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Casanova, Bello Monte',
  },
  {
    id: 21,
    name: 'Rydaca',
    city: 'Lecheria',
    state: 'Anzoategui',
    address: 'C.C Costanera Lecheria',
  },
  {
    id: 22,
    name: 'Santa Clara',
    city: 'Boto',
    state: 'Lara',
    address: 'Calle 4, Av. Lara / Carrera 1',
  },
  {
    id: 23,
    name: 'Ceramicas del Norte',
    city: 'Valencia',
    state: 'Carabobo',
    address: 'Av. Carabobo Nro 141-91',
  },
  {
    id: 24,
    name: 'Total Pisos',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Luis de Camoens, Edf Torre Express',
  },
  {
    id: 25,
    name: 'Super Baños',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Calle Orinoco, Las Mercedes',
  },
  {
    id: 26,
    name: 'Dr Baños',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Av. Principal La Urbina',
  },
  {
    id: 27,
    name: 'Keops',
    city: 'Lecheria',
    state: 'Anzoategui',
    address: '',
  },
  {
    id: 28,
    name: 'DG Group PZO',
    city: 'PTO Ordaz',
    state: 'Bolivar',
    address: 'C.C Costa America',
  },
  {
    id: 29,
    name: 'Roca del Este',
    city: 'Caracas',
    state: 'Miranda',
    address: 'Los Naranjos',
  },
];
