type ClientInfo = {
  name?: string;
  dni?: string;
  phone?: string;
  email?: string;
};

type AcquireProduct = {
  product?: string;
  mts2?: string;
};

export type ExchangeFormState = {
  image: File;
  store: string;
  urlImg: string;
  attention: string;
  seller: string;
  sellerInfo: string;
  project: string;
  otherProject: string;
  benefitProject: string;
  uploadValue: number;
  numberId: string;
  finalClient: ClientInfo;
  architect?: ClientInfo;
  productMts: Array<AcquireProduct>;
};

export function buildInitialState(): ExchangeFormState {
  return {
    image: null,
    store: 'none',
    urlImg: '',
    attention: '5',
    seller: 'none',
    sellerInfo: 'seller',
    project: 'none',
    otherProject: 'Otro',
    benefitProject: 'No',
    uploadValue: 0,
    numberId: '',
    finalClient: {
      name: '',
      dni: '',
      phone: '',
      email: '',
    },
    architect: {
      name: '',
      dni: '',
      phone: '',
      email: '',
    },
    productMts: [
      {
        product: 'none',
        mts2: '',
      },
    ],
  };
}

export type Actions =
  | { type: 'IMAGE'; payload: File }
  | { type: 'STORE'; payload: string }
  | { type: 'URL_IMAGE'; payload: string }
  | { type: 'ATTENTION'; payload: string }
  | { type: 'SELLER'; payload: string }
  | { type: 'SELLER_INFO'; payload: string }
  | { type: 'PROJECT'; payload: string }
  | { type: 'OTHER_PROJECT'; payload: string }
  | { type: 'BENEFIT_PROJECT'; payload: string }
  | { type: 'UPLOAD_VALUE'; payload: number }
  | { type: 'NUMBER_ID'; payload: string }
  | { type: 'FINAL_CLIENT'; payload: ClientInfo }
  | { type: 'FINAL_CLIENT_NAME'; payload: string }
  | { type: 'FINAL_CLIENT_DNI'; payload: string }
  | { type: 'FINAL_CLIENT_EMAIL'; payload: string }
  | { type: 'FINAL_CLIENT_PHONE'; payload: string }
  | { type: 'ARCHITECT'; payload: ClientInfo }
  | { type: 'ARCHITECT_NAME'; payload: string }
  | { type: 'ARCHITECT_DNI'; payload: string }
  | { type: 'ARCHITECT_EMAIL'; payload: string }
  | { type: 'ARCHITECT_PHONE'; payload: string }
  | { type: 'PRODUCTS_ACQUIRE'; payload: Array<AcquireProduct> }
  | { type: 'PRODUCTS_ACQUIRE_PRODUCT'; payload: { idx: number; data: string } }
  | { type: 'PRODUCTS_ACQUIRE_MTS2'; payload: { idx: number; data: string } }
  | { type: 'PRODUCTS_ACQUIRE_DELETE_PRODUCT'; payload: { idx: number } };

export const ACTIONS_TYPES = {
  IMAGE: 'IMAGE',
  STORE: 'STORE',
  URL_IMAGE: 'URL_IMAGE',
  ATTENTION: 'ATTENTION',
  SELLER: 'SELLER',
  SELLER_INFO: 'SELLER_INFO',
  PROJECT: 'PROJECT',
  OTHER_PROJECT: 'OTHER_PROJECT',
  BENEFIT_PROJECT: 'BENEFIT_PROJECT',
  UPLOAD_VALUE: 'UPLOAD_VALUE',
  NUMBER_ID: 'NUMBER_ID',
  FINAL_CLIENT: 'FINAL_CLIENT',
  FINAL_CLIENT_NAME: 'FINAL_CLIENT_NAME',
  FINAL_CLIENT_DNI: 'FINAL_CLIENT_DNI',
  FINAL_CLIENT_EMAIL: 'FINAL_CLIENT_EMAIL',
  FINAL_CLIENT_PHONE: 'FINAL_CLIENT_PHONE',
  ARCHITECT: 'ARCHITECT',
  ARCHITECT_NAME: 'ARCHITECT_NAME',
  ARCHITECT_DNI: 'ARCHITECT_DNI',
  ARCHITECT_EMAIL: 'ARCHITECT_EMAIL',
  ARCHITECT_PHONE: 'ARCHITECT_PHONE',
  PRODUCTS_ACQUIRE: 'PRODUCTS_ACQUIRE',
  PRODUCTS_ACQUIRE_PRODUCT: 'PRODUCTS_ACQUIRE_PRODUCT',
  PRODUCTS_ACQUIRE_MTS2: 'PRODUCTS_ACQUIRE_MTS2',
  PRODUCTS_ACQUIRE_DELETE_PRODUCT: 'PRODUCTS_ACQUIRE_DELETE_PRODUCT',
} as const;

export function reducer(
  state: ExchangeFormState,
  action: Actions
): ExchangeFormState {
  switch (action.type) {
    case ACTIONS_TYPES.IMAGE:
      return { ...state, image: action.payload };
    case ACTIONS_TYPES.STORE:
      return { ...state, store: action.payload };
    case ACTIONS_TYPES.URL_IMAGE:
      return { ...state, urlImg: action.payload };
    case ACTIONS_TYPES.ATTENTION:
      return { ...state, attention: action.payload };
    case ACTIONS_TYPES.SELLER:
      return { ...state, seller: action.payload };
    case ACTIONS_TYPES.SELLER_INFO:
      return { ...state, sellerInfo: action.payload };
    case ACTIONS_TYPES.PROJECT:
      return { ...state, project: action.payload };
    case ACTIONS_TYPES.OTHER_PROJECT:
      return { ...state, otherProject: action.payload };
    case ACTIONS_TYPES.BENEFIT_PROJECT:
      return { ...state, benefitProject: action.payload };
    case ACTIONS_TYPES.UPLOAD_VALUE:
      return { ...state, uploadValue: action.payload };
    case ACTIONS_TYPES.NUMBER_ID:
      return { ...state, numberId: action.payload };
    case ACTIONS_TYPES.FINAL_CLIENT:
      return { ...state, finalClient: action.payload };
    case ACTIONS_TYPES.PRODUCTS_ACQUIRE:
      return { ...state, productMts: action.payload };
    case ACTIONS_TYPES.PRODUCTS_ACQUIRE_DELETE_PRODUCT:
      return {
        ...state,
        productMts: [
          ...(state?.productMts?.slice(0, action.payload.idx) ?? []),
          ...(state?.productMts?.slice(action.payload.idx + 1) ?? []),
        ],
      };
    case ACTIONS_TYPES.PRODUCTS_ACQUIRE_PRODUCT:
      return {
        ...state,
        productMts: [
          ...(state?.productMts?.slice(0, action.payload.idx) ?? []),
          {
            ...(state?.productMts?.[action.payload.idx] ?? {}),
            product: action.payload.data,
          },
          ...(state?.productMts?.slice(action.payload.idx + 1) ?? []),
        ],
      };
    case ACTIONS_TYPES.PRODUCTS_ACQUIRE_MTS2:
      return {
        ...state,
        productMts: [
          ...(state?.productMts?.slice(0, action.payload.idx) ?? []),
          {
            ...(state?.productMts?.[action.payload.idx] ?? {}),
            mts2: action.payload.data,
          },
          ...(state?.productMts?.slice(action.payload.idx + 1) ?? []),
        ],
      };
    case ACTIONS_TYPES.FINAL_CLIENT_NAME:
      return {
        ...state,
        finalClient: {
          ...(state.finalClient ?? {}),
          name: action.payload,
        },
      };
    case ACTIONS_TYPES.FINAL_CLIENT_DNI:
      return {
        ...state,
        finalClient: {
          ...(state.finalClient ?? {}),
          dni: action.payload,
        },
      };
    case ACTIONS_TYPES.FINAL_CLIENT_EMAIL:
      return {
        ...state,
        finalClient: {
          ...(state.finalClient ?? {}),
          email: action.payload,
        },
      };
    case ACTIONS_TYPES.FINAL_CLIENT_PHONE:
      return {
        ...state,
        finalClient: {
          ...(state.finalClient ?? {}),
          phone: action.payload,
        },
      };
    case ACTIONS_TYPES.ARCHITECT:
      return { ...state, architect: action.payload };
    case ACTIONS_TYPES.ARCHITECT_NAME:
      return {
        ...state,
        architect: {
          ...(state.architect ?? {}),
          name: action.payload,
        },
      };
    case ACTIONS_TYPES.ARCHITECT_DNI:
      return {
        ...state,
        architect: {
          ...(state.architect ?? {}),
          dni: action.payload,
        },
      };
    case ACTIONS_TYPES.ARCHITECT_EMAIL:
      return {
        ...state,
        architect: {
          ...(state.architect ?? {}),
          email: action.payload,
        },
      };
    case ACTIONS_TYPES.ARCHITECT_PHONE:
      return {
        ...state,
        architect: {
          ...(state.architect ?? {}),
          phone: action.payload,
        },
      };
    default:
      return { ...state };
  }
}
