import React from 'react';
import Swal from 'sweetalert2';
import { validateString } from 'avilatek-utils';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ref, orderByChild, equalTo, get, query } from 'firebase/database';
import { auth, database } from '../../../lib/firebase';

export default function ForgotPassword() {
  const [email, setEmail] = React.useState('');

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateString(email)) {
      Swal.fire({
        icon: 'error',
        text: `Debe ingresar su correo electrónico`,
      });
      return;
    }
    const _query = query(
      ref(database, 'users'),
      orderByChild('email'),
      equalTo(email)
    );
    const snapshot = await get(_query);
    let verify = false;
    if (snapshot.exists() && snapshot.val().email !== '') {
      verify = true;
    } else {
      verify = false;
    }
    if (verify) {
      await sendPasswordResetEmail(auth, email);
      Swal.fire({
        icon: 'success',
        text: `Se ha enviado un link para restablecer tu contraseña a tu correo:${email}`,
      });
    } else {
      Swal.fire({
        icon: 'error',
        text: `El correo suministrado: ${email} no esta registrado en nuestro sistema`,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      default:
        break;
    }
  };
  return (
    <form>
      <div className="card" data-background="color" data-color="blue">
        <div className="card-header">
          <h3 className="card-title text-center">Restablecer contraseña</h3>
        </div>
        <div className="card-content">
          <div className="container">
            <h5 className="text-justify">
              Ingrese su correo electrónico registrado en nuestra plataforma,
              donde le estaremos enviando un correo para que pueda restablecer
              su contraseña
            </h5>
          </div>
          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <h6>Correo electrónico</h6>
                <input
                  type="email"
                  value={email}
                  placeholder="Enter email"
                  id="email"
                  name="email"
                  className="form-control input-no-border"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-center py-3">
          <button
            onClick={handleSubmit}
            className="btn btn-fill btn-wd"
            type="button"
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
}
