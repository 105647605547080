/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { ref, onValue } from 'firebase/database';
import { CardProduct, Spinner } from '../..';
import { Product } from '../../../models';
import { database } from '../../../lib/firebase';
import ProductPagination from './ProductPagination';

export default function Products() {
  const [products, setProducts] = React.useState<Product[]>([]);
  const [filter, setFilter] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [numberPerPage, setNumberPage] = React.useState(6);
  const [pageNumbers, setPageNumbers] = React.useState<number[]>([]);
  const indexOfLastTodo = currentPage * numberPerPage;
  const indexOfFirstTodo = indexOfLastTodo - numberPerPage;
  const currentTodos = products.slice(indexOfFirstTodo, indexOfLastTodo);

  const productsFilter = currentTodos.filter(
    (item) => item.title.toLowerCase().indexOf(filter) !== -1
  );

  React.useEffect(
    function calculatePageNumbers() {
      const pages = new Array(Math.ceil(products.length / numberPerPage)).fill(
        0
      );
      setPageNumbers(pages?.map((_, index) => index + 1));
    },
    [products?.length, numberPerPage]
  );

  React.useEffect(function syncProductFromDb() {
    const helper: any[] = [];
    const unsubscribe = onValue(ref(database, 'productsPoints'), (snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((item) => {
          if (item.val().status) {
            helper.push(item.val());
          }
        });
        setProducts(helper);
      }
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (name === 'filter') {
      setFilter(value);
    }
  };

  const changePage = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const { page } = e.currentTarget.dataset;
    document.getElementById(`${currentPage}`).classList.remove('active');
    document.getElementById(`${page}`).classList.add('active');
    setCurrentPage(Number(page));
  };

  const prevPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (currentPage !== 1) {
      document.getElementById(`${currentPage - 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (currentPage !== pageNumbers.length) {
      document.getElementById(`${currentPage + 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      {products && products.length > 0 ? (
        <>
          <div className="container">
            <div className="content-fluid">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <label htmlFor="filter">Filtrar producto</label>
                  <input
                    name="filter"
                    value={filter}
                    onChange={handleChange}
                    placeholder=" ej: Ceramica"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                {productsFilter.map((_product, id) => (
                  <div className="col-lg-4 col-md-6" key={id}>
                    <CardProduct product={_product} key={id} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
          <ProductPagination
            prevPage={prevPage}
            nextPage={nextPage}
            pageNumbers={pageNumbers}
            changePage={changePage}
          />
        </>
      ) : (
        <div className="container" style={{ height: '100vh' }}>
          <div className="row justify-content-center">
            <div style={{ position: 'relative', top: '300px' }}>
              <Spinner text="Cargando..." />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
