/* eslint-disable no-nested-ternary */
import React from 'react';
import Link from 'next/link';
import { ref, query, orderByChild, equalTo, onValue } from 'firebase/database';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { Spinner, UserLayout } from '../..';
import { usePagination, useUser } from '../../../hooks';
import { database } from '../../../lib/firebase';

export default function Exchanges() {
  const [user] = useUser();
  const [exchanges, setExchanges] = React.useState([]);
  const { currentPage, setCurrentPage, pageNumbers, items } =
    usePagination(exchanges);
  React.useEffect(
    function syncExchanges() {
      if (user?.idUser) {
        onValue(
          query(
            ref(database, 'requets'),
            orderByChild('id_user'),
            equalTo(user.idUser)
          ),
          (snapshot) => {
            const data = [];
            snapshot.forEach((child) => {
              data.push(child.val());
            });
            setExchanges(data);
          }
        );
      }
    },
    [user?.idUser]
  );

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const { page } = e.currentTarget.dataset;
    document.getElementById(`${currentPage}`).classList.remove('active');
    document.getElementById(`${page}`).classList.add('active');
    setCurrentPage(Number(page));
  };

  const prevPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (currentPage !== 1) {
      e.preventDefault();
      document.getElementById(`${currentPage - 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (currentPage !== pageNumbers.length) {
      e.preventDefault();
      document.getElementById(`${currentPage + 1}`).classList.add('active');
      document.getElementById(`${currentPage}`).classList.remove('active');
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <UserLayout>
      {user ? (
        exchanges && exchanges.length > 0 && items && items.length > 0 ? (
          <>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-content table-responsive table-full-width">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th key="date">
                            <p className="text-center">Fecha</p>
                          </th>
                          <th key="store">
                            <p className="text-center">Puntos</p>
                          </th>
                          <th key="status">
                            <p className="text-center">Producto</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((exchange, id) => (
                          <tr key={id}>
                            <td className="text-center">{exchange.date}</td>
                            <td className="text-center">{exchange.points}</td>
                            <td className="text-center">{exchange.product}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="container">
              <div className="row justify-content-center">
                <div className="card-content">
                  <ul className="pagination">
                    <li>
                      <a
                        onClick={prevPage}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <i aria-hidden>
                          <FaArrowCircleLeft size={20} />
                        </i>
                      </a>
                    </li>
                    {pageNumbers.map((page) => (
                      <li
                        id={String(page)}
                        key={page}
                        data-page={page}
                        onClick={handleClick}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                        role="button"
                        tabIndex={0}
                      >
                        <a>{page}</a>
                      </li>
                    ))}
                    <li>
                      <a
                        onClick={nextPage}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <i aria-hidden>
                          <FaArrowCircleRight size={20} />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <h4 className="text-center">No hay ningun producto canjeado</h4>
              </div>
            </div>
            <div className="row justify-content-center">
              <Link
                href="/products"
                className="btn btn-default"
                style={{ position: 'relative', left: '1.5%' }}
              >
                Realizar un canjeo
              </Link>
            </div>
          </div>
        )
      ) : (
        <div className="container" style={{ height: '100vh' }}>
          <div className="row justify-content-center">
            <div style={{ position: 'relative', top: '300px' }}>
              <Spinner text="Cargando..." />
            </div>
          </div>
        </div>
      )}
    </UserLayout>
  );
}
