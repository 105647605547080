/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import {
  ref as dbRef,
  onValue,
  push,
  set,
  query,
  orderByChild,
  equalTo,
  update,
} from 'firebase/database';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { validateNumber, validateString } from 'avilatek-utils';
import { Stores as stores } from '../../../public/assets/lib/stores';
import { Employee, HabitatProduct } from '../../../models';
import { database, storage } from '../../../lib/firebase';
import { ACTIONS_TYPES, buildInitialState, reducer } from './reducer';
import { useUser } from '../../../hooks';
import UserLayout from '../../layout/UserLayout';
import { USER_TYPES } from '../../../lib/constants';

export default function Exchange() {
  const router = useRouter();
  const [user] = useUser();
  const [state, dispatch] = React.useReducer(reducer, buildInitialState());
  const [products, setProducts] = React.useState<HabitatProduct[]>([]);
  const [employees, setEmployees] = React.useState<Employee[]>([]);
  React.useEffect(function syncEmployees() {
    const unsubscribe = onValue(
      query(dbRef(database, 'users'), orderByChild('status'), equalTo(2)),
      (snapshot) => {
        const data = [];
        const storeData = new Set<string>();
        if (snapshot.exists()) {
          snapshot.forEach((child) => {
            const val = child.val();
            if (val?.status === 2) {
              data.push({ ...val, key: child.key });
              storeData.add(val.store);
            }
          });
          setEmployees(data);
        }
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  React.useEffect(function syncProducts() {
    const _products = [];
    const unsubscribe = onValue(dbRef(database, 'products'), (snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((item) => {
          _products.push(item.val());
        });
      }
      setProducts(_products?.sort((a, b) => (a.name > b.name ? -1 : 1)) ?? []);
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const onChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    dispatch({ type: e.target.name as any, payload: e.target.value });
  };

  const removeUpload = () => {
    document.getElementById('upload_content').style.display = 'none';
    document.getElementById('upload_wrap').hidden = false;
    document.getElementById('preview_image').setAttribute('src', '/');
    dispatch({ type: ACTIONS_TYPES.IMAGE, payload: null });
    dispatch({ type: ACTIONS_TYPES.URL_IMAGE, payload: '' });
    dispatch({ type: ACTIONS_TYPES.UPLOAD_VALUE, payload: 0 });
  };

  const readUrl = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e?.target?.files?.[0];
    if (file) {
      dispatch({ type: ACTIONS_TYPES.IMAGE, payload: file });
      const task = uploadBytesResumable(
        ref(storage, `solicitudes/${file.name}`),
        file
      );
      task.on(
        'state_changed',
        (snapshot) => {
          const percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          dispatch({ type: ACTIONS_TYPES.UPLOAD_VALUE, payload: percentage });
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(task.snapshot.ref).then((url) => {
            dispatch({ type: ACTIONS_TYPES.URL_IMAGE, payload: url });
            document.getElementById('upload_wrap').hidden = true;
            document
              .getElementById('preview_image')
              .setAttribute('src', URL.createObjectURL(file));
            document.getElementById('upload_content').style.display = 'block';
          });
        }
      );
    } else {
      removeUpload();
    }
  };

  const sendImage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      if (!validateString(state.store) || state.store === 'none') {
        Swal.fire({
          icon: 'error',
          text: `Seleccione una tienda`,
        });
        return;
      }
      if (!validateString(state.seller) || state.seller === 'none') {
        Swal.fire({
          icon: 'error',
          text: `Ingrese el nombre del vendedor`,
        });
        return;
      }
      if (!validateString(state.attention)) {
        Swal.fire({
          icon: 'error',
          text: `Seleccione un nivel de atención`,
        });
        return;
      }
      if (!validateString(state.project)) {
        Swal.fire({
          icon: 'error',
          text: `Ingrese un nombre del proyecto`,
        });
        return;
      }
      if (!state.image || !validateString(state.urlImg)) {
        Swal.fire({
          icon: 'error',
          text: `Ingrese una imagen del proyecto`,
        });
        return;
      }
      // if (!validateProducts(state.productMts)) {
      //   Swal.fire({
      //     icon: 'error',
      //     text: `Hay un error en los productos`,
      //   });
      //   return;
      // }
      if (!validateString(state.numberId)) {
        Swal.fire({
          icon: 'error',
          text: `Ingrese un número de documento`,
        });
        return;
      }
      const date = new Date();
      const result = await Swal.fire({
        title: '¿Estas seguro?',
        text: 'Su solicitud sera enviada para abonarle los puntos obtenidos',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Enviar solicitud',
      });
      if (result.value) {
        const newRequestRef = push(dbRef(database, 'requets'));
        await update(newRequestRef.ref, {
          id: newRequestRef.key,
          img: state.urlImg,
          id_user: user.idUser,
          date: dayjs(date).format('DD/MM/YYYY'),
          store: state.store,
          status: 'procesando',
          attention: state.attention,
          purchase: state.productMts,
          number_bill: state.numberId,
          seller: state.seller,
          project_type: validateString(state.otherProject)
            ? state.otherProject
            : state.project,
          client_final:
            [
              { ...(state.finalClient ?? {}), type: 'Cliente' },
              { ...(state.architect ?? {}), type: 'Arquitecto / Diseñador' },
            ] || [],
          benefictProject: state.benefitProject ?? '',
        });
        await router.push('/user/request');
      }
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        text: `Ha ocurrido un error al guardar la información`,
      });
    }
  };

  const addProduct = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      dispatch({
        type: ACTIONS_TYPES.PRODUCTS_ACQUIRE,
        payload: [...(state?.productMts ?? []), { product: 'none', mts2: '' }],
      });
    },
    [state.productMts]
  );
  const removeProduct = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      const { id } = e.currentTarget.dataset;
      dispatch({
        type: ACTIONS_TYPES.PRODUCTS_ACQUIRE_DELETE_PRODUCT,
        payload: { idx: Number(id) },
      });
    },
    []
  );

  return (
    <UserLayout>
      <form method="POST">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card rounded-lg shadow-sm">
              <div className="card-headerbc">
                <h5 className="text-center py-4">
                  Registro del documento de compra en Habitat Venezuela
                </h5>
                <p className="text-justify py-2 px-4">
                  La solicitud de Habitat Coins consiste en registrar datos
                  relacionados con la compra de productos Habitat Venezuela. Una
                  vez realizada la solicitud debes esperar 72 horas para que sea
                  aprobada y de esta manera obtener los Habitat Coins que
                  correspondan según tu compra y que luego podrás canjear por
                  productos o servicios especiales.
                </p>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* TIENDA */}
                  <div className="col-md-6">
                    <label htmlFor={ACTIONS_TYPES.STORE}>
                      Nombre de la tienda:
                    </label>
                    <select
                      id={ACTIONS_TYPES.STORE}
                      name={ACTIONS_TYPES.STORE}
                      className="form-control"
                      onChange={onChange}
                      value={state.store}
                      required
                    >
                      <option value="none" disabled>
                        Selecciona una tienda
                      </option>
                      {stores?.map((_store) => (
                        <option value={_store?.name} key={_store?.name}>
                          {_store?.name} - {_store?.city} - {_store?.address}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Vendedor */}
                  <div className="col-md-6">
                    <label htmlFor={ACTIONS_TYPES.SELLER}>
                      Persona que lo atendió:
                    </label>
                    <select
                      id={ACTIONS_TYPES.SELLER}
                      name={ACTIONS_TYPES.SELLER}
                      className="form-control"
                      onChange={onChange}
                      value={state.seller}
                      required
                    >
                      <option value="none" disabled>
                        Selecciona un vendedor
                      </option>
                      {employees?.length > 0 ? (
                        employees?.map((seller) => (
                          <option value={seller?.key} key={seller?.key}>
                            {seller?.first_name} {seller?.first_lastname}
                          </option>
                        ))
                      ) : (
                        <option>No hay vendedores</option>
                      )}
                    </select>
                  </div>
                  {/* Proyecto */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.PROJECT}>
                      Tipo de proyecto:
                    </label>
                    <select
                      name={ACTIONS_TYPES.PROJECT}
                      id={ACTIONS_TYPES.PROJECT}
                      className="form-control"
                      onChange={onChange}
                      value={state.project}
                      required
                    >
                      <option value="casa">Casa</option>
                      <option value="apartamento">Apartamento</option>
                      <option value="oficina">Oficina</option>
                      <option value="restaurante">Restaurante</option>
                      <option value="bodegon">Bodegón</option>
                      <option value="supermercado">Supermercado</option>
                      <option value="hotel">Hotel</option>
                      <option value="otro">Otro</option>
                    </select>
                  </div>
                  {/* Otro Proyecto (?) */}
                  {state.project === 'otro' ? (
                    <>
                      <div className="col-md-6" style={{ marginTop: '1rem' }}>
                        <label htmlFor={ACTIONS_TYPES.OTHER_PROJECT}>
                          Especifique
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name={ACTIONS_TYPES.OTHER_PROJECT}
                          id={ACTIONS_TYPES.OTHER_PROJECT}
                          placeholder="Especifique"
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="col-md-6" style={{ marginTop: '1rem' }} />
                    </>
                  ) : null}
                  {/* Atención */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.ATTENTION}>
                      Nivel de atención:
                    </label>
                    <select
                      id={ACTIONS_TYPES.ATTENTION}
                      name={ACTIONS_TYPES.ATTENTION}
                      className="form-control"
                      onChange={onChange}
                      value={state.attention}
                      required
                    >
                      <option value="5">Excelente</option>
                      <option value="4">Muy bien</option>
                      <option value="3">Bien</option>
                      <option value="2">Mal</option>
                      <option value="1">Muy mal</option>
                    </select>
                  </div>
                  {/* Factura */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.NUMBER_ID}>
                      N° de documento que soporte la compra:
                    </label>
                    <input
                      id={ACTIONS_TYPES.NUMBER_ID}
                      name={ACTIONS_TYPES.NUMBER_ID}
                      className="form-control"
                      type="text"
                      onChange={onChange}
                      value={state.numberId}
                      placeholder="N° de documento que soporte la compra"
                      required
                    />
                  </div>
                  {/* Beneficio */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.BENEFIT_PROJECT}>
                      Su proyecto fue beneficiado con algún descuento de apoyo
                      al sector turismo y/o educacion que otorga la marca a
                      estos rubros?
                    </label>
                    <select
                      id={ACTIONS_TYPES.BENEFIT_PROJECT}
                      name={ACTIONS_TYPES.BENEFIT_PROJECT}
                      onChange={onChange}
                      placeholder="No"
                      className="form-control"
                    >
                      <option value="si">Si</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  {/* Productos */}
                  {state.productMts.map((_, idx) => (
                    <React.Fragment key={idx}>
                      <div className="col-md-6" style={{ marginTop: '1rem' }}>
                        <label htmlFor={`product-${idx}`}>Producto</label>
                        <select
                          name={`product-${idx}`}
                          id={`product-${idx}`}
                          className="form-control"
                          data-id={idx}
                          onChange={(e) => {
                            e.preventDefault();
                            dispatch({
                              type: ACTIONS_TYPES.PRODUCTS_ACQUIRE_PRODUCT,
                              payload: {
                                idx,
                                data: e.target.value,
                              },
                            });
                          }}
                          defaultValue="none"
                          required
                        >
                          <option value="none" disabled>
                            Seleccione un producto
                          </option>
                          {products.map((product) => (
                            <option
                              value={product?.name ?? ''}
                              key={product?.code ?? ''}
                            >
                              {product?.name ?? ''}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6" style={{ marginTop: '1rem' }}>
                        <label htmlFor={`mt2-${idx}`}>Metros cuadrados:</label>
                        <input
                          type="text"
                          name={`mt2-${idx}`}
                          id={`mt2-${idx}`}
                          className="form-control"
                          data-id={idx}
                          onChange={(e) => {
                            e.preventDefault();
                            dispatch({
                              type: ACTIONS_TYPES.PRODUCTS_ACQUIRE_MTS2,
                              payload: {
                                idx,
                                data: e.target.value,
                              },
                            });
                          }}
                          required
                        />
                      </div>
                    </React.Fragment>
                  ))}
                  {/* Agregar producto */}
                  <div className="col-md-12" style={{ marginTop: '1rem' }}>
                    <button
                      className="btn btn-default"
                      style={{ float: 'right' }}
                      onClick={addProduct}
                      type="button"
                    >
                      Agregar Producto
                    </button>
                    {state.productMts?.length > 1 ? (
                      <button
                        className="btn btn-default mx-3"
                        style={{ float: 'right' }}
                        onClick={removeProduct}
                        data-id={(state.productMts?.length ?? 1) - 1}
                        type="button"
                      >
                        Eliminar Producto
                      </button>
                    ) : null}
                  </div>
                  {/* Información cliente */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.FINAL_CLIENT_NAME}>
                      Nombre del cliente final del proyecto (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.FINAL_CLIENT_NAME}
                      name={ACTIONS_TYPES.FINAL_CLIENT_NAME}
                      type="text"
                      className="form-control"
                      placeholder="Nombre del cliente final del proyecto (opcional)"
                      value={state.finalClient.name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.FINAL_CLIENT_DNI}>
                      Cédula del cliente final del proyecto (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.FINAL_CLIENT_DNI}
                      name={ACTIONS_TYPES.FINAL_CLIENT_DNI}
                      type="text"
                      className="form-control"
                      placeholder="Cédula del cliente final del proyecto (opcional)"
                      value={state.finalClient.dni}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.FINAL_CLIENT_PHONE}>
                      Teléfono del cliente final del proyecto (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.FINAL_CLIENT_PHONE}
                      name={ACTIONS_TYPES.FINAL_CLIENT_PHONE}
                      type="text"
                      className="form-control"
                      placeholder="Teléfono del cliente final del proyecto (opcional)"
                      value={state.finalClient.phone}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.FINAL_CLIENT_EMAIL}>
                      Correo Electrónico del cliente final del proyecto
                      (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.FINAL_CLIENT_EMAIL}
                      name={ACTIONS_TYPES.FINAL_CLIENT_EMAIL}
                      type="email"
                      className="form-control"
                      placeholder="Correo Electrónico del cliente final del proyecto (opcional)"
                      value={state.finalClient.email}
                      onChange={onChange}
                    />
                  </div>
                  {/* Información Arquitecto */}
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.ARCHITECT_NAME}>
                      Nombre del Arquitecto / Diseñador del proyecto (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.ARCHITECT_NAME}
                      name={ACTIONS_TYPES.ARCHITECT_NAME}
                      type="text"
                      className="form-control"
                      placeholder="Nombre del Arquitecto / Diseñador del proyecto (opcional)"
                      value={state.architect.name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.ARCHITECT_DNI}>
                      Cédula del Arquitecto / Diseñador del proyecto (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.ARCHITECT_DNI}
                      name={ACTIONS_TYPES.ARCHITECT_DNI}
                      type="text"
                      className="form-control"
                      placeholder="Cédula del Arquitecto / Diseñador del proyecto (opcional)"
                      value={state.architect.dni}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.ARCHITECT_PHONE}>
                      Teléfono del Arquitecto / Diseñador del proyecto
                      (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.ARCHITECT_PHONE}
                      name={ACTIONS_TYPES.ARCHITECT_PHONE}
                      type="text"
                      className="form-control"
                      placeholder="Teléfono del Arquitecto / Diseñador del proyecto (opcional)"
                      value={state.architect.phone}
                      onChange={onChange}
                    />
                  </div>
                  <div className="col-md-6" style={{ marginTop: '1rem' }}>
                    <label htmlFor={ACTIONS_TYPES.ARCHITECT_EMAIL}>
                      Correo Electrónico del Arquitecto / Diseñador del proyecto
                      (opcional)
                    </label>
                    <input
                      id={ACTIONS_TYPES.ARCHITECT_EMAIL}
                      name={ACTIONS_TYPES.ARCHITECT_EMAIL}
                      type="email"
                      className="form-control"
                      placeholder="Correo Electrónico del Arquitecto / Diseñador del proyecto (opcional)"
                      value={state.architect.email}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-6">
            <div className="file-upload">
              <div className="image-upload-wrap" id="upload_wrap">
                <input
                  className="file-upload-input"
                  type="file"
                  accept="image/*, application/pdf"
                  id="input"
                  onChange={readUrl}
                />
                <div className="drag-text">
                  <h3>Seleccione su documento que soporte la compra</h3>
                </div>
              </div>
              <div className="file-upload-content" id="upload_content">
                <img
                  className="file-upload-image"
                  src="#"
                  alt="factura"
                  id="preview_image"
                />
                <div className="image-title-wrap">
                  <button
                    type="button"
                    className="remove-image"
                    onClick={removeUpload}
                  >
                    Eliminar{' '}
                    <span className="image-title">
                      {state.image?.name ?? ''}
                    </span>
                  </button>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={state.uploadValue}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: `${state.uploadValue}%` }}
                  >
                    <span className="sr-only">
                      {state.uploadValue === 100 ? 'Listo' : 'Cargando...'}
                      {Math.round(state.uploadValue)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-md-10">
            {state.image && state.uploadValue === 100 ? (
              <button
                className="btn btn-default"
                type="submit"
                style={{ float: 'right' }}
                onClick={sendImage}
              >
                Enviar
              </button>
            ) : (
              <button
                className="btn btn-default"
                type="submit"
                style={{ float: 'right' }}
                disabled
              >
                Enviar
              </button>
            )}
          </div>
        </div>
      </form>
    </UserLayout>
  );
}
