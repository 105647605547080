import React from 'react';
import Products from './Products';
import { UserLayout } from '../..';

export default function UserProducts() {
  return (
    <UserLayout>
      <Products />
    </UserLayout>
  );
}
