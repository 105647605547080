import React from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

interface ProductPaginationProps {
  prevPage: React.MouseEventHandler<HTMLAnchorElement>;
  nextPage: React.MouseEventHandler<HTMLAnchorElement>;
  pageNumbers: number[];
  changePage: React.MouseEventHandler<HTMLLIElement>;
}

export default function ProductPagination({
  prevPage,
  nextPage,
  pageNumbers,
  changePage,
}: ProductPaginationProps) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="card-content">
          <ul className="pagination">
            <li>
              <a
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onClick={prevPage}
              >
                <i aria-hidden>
                  <FaArrowCircleLeft size={20} />
                </i>
              </a>
            </li>
            {pageNumbers.map((page) => (
              <li
                id={String(page)}
                key={page}
                data-page={page}
                onClick={changePage}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              >
                <a>{page}</a>
              </li>
            ))}
            <li>
              <a
                onClick={nextPage}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              >
                <i aria-hidden>
                  <FaArrowCircleRight size={20} />
                </i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
