/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { RiFacebookCircleLine } from 'react-icons/ri';
import { FaInstagram } from 'react-icons/fa';
import { ContactUs, Navbar, Footer, Slider } from '../..';

const images = [
  '../../assets/img/Banner-1.png',
  '../../assets/img/Banner-2.png',
  '../../assets/img/Banner-3.png',
];

export function Home() {
  return (
    <>
      <Navbar image="../../assets/img/logoMarketplace.png" />
      <div style={{ height: '100vh' }}>
        <Slider images={images} controls />
      </div>
      <section
        className="services-area pt-120"
        style={{
          height: 'auto',
          backgroundImage: "url('../../assets/img/indexBackground.jpg')",
        }}
      >
        <div className="upper-section" id="steps">
          <div className="container">
            <div
              className="row justify-content-center"
              style={{ marginBottom: 15 }}
            >
              <div className="col-lg-10">
                <div
                  className="section title text-center pb-40"
                  style={{ marginTop: '3rem' }}
                >
                  <h2
                    className="title"
                    style={{ color: 'black' }}
                    id="steps-title"
                  >
                    Pasos para adquirir productos o servicios:
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-7 col-sm-8">
                <div
                  className="single-services text-center mt-30"
                  style={{ height: '90%' }}
                >
                  <div className="services-icon" />
                  <div className="services-content mt-30">
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{
                          width: '100px',
                          height: '100px',
                          color: 'white',
                          marginLeft: '20px',
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                        />
                      </svg>
                    </div>
                    <h4 className="services-title text-white">Regístrate</h4>
                    <p className="text">
                      Es muy sencillo, solo necesitamos tu información para que
                      puedas solicitar tus Habitat Coins para luego canjearlos
                      por productos o servicios.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 col-sm-8">
                <div
                  className="single-services text-center mt-30"
                  style={{ height: '90%' }}
                >
                  <div className="services-icon" />
                  <div className="services-content mt-30">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{
                          width: '100px',
                          height: '100px',
                          color: 'white',
                          marginLeft: '20px',
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                    </div>
                    <h4 className="services-title text-white">
                      Sube tu documento de compra
                    </h4>
                    <p className="text">
                      En la sección de Solicitud de Habitat Coins debes subir tu
                      factura o cotización y completar los datos requeridos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 col-sm-8">
                <div
                  className="single-services text-center mt-30"
                  style={{ height: '90%' }}
                >
                  <div className="services-icon" />
                  <div className="services-content mt-30">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{
                          width: '100px',
                          height: '100px',
                          color: 'white',
                          marginLeft: '20px',
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                    </div>
                    <h4 className="services-title text-white">
                      Canjea los productos
                    </h4>
                    <p className="text">
                      Una vez que sea aprobada tu solicitud, serán cargados a tu
                      cuenta los Habitat Coins generados por tu compra. Una vez
                      obtenidos, podrás canjearlos por productos o servicios de
                      tu preferencia en el Market Place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <h2
          className="text-center pb-5"
          style={{ margin: '0rem', color: 'black' }}
          id="marketPlace-title"
        >
          ¿Qué es el Market Place?
        </h2>
        <div
          className="container"
          style={{ paddingBottom: '6rem', height: 'auto' }}
          id="about"
        >
          <div className="row">
            <h5 className="text-center" id="textMarketPlace">
              Es la plataforma de nuestro programa exclusivo de fidelización,
              aquí consentimos a los nuestros, reconocemos el valor y la
              constancia de cada cliente que continúa haciendo futuro en
              Venezuela. Estamos haciendo crecer la familia HABITAT Venezuela,
              la familia que sigue y seguirá #PisandoFirme en todo el país.
            </h5>
          </div>
          <div className="row justify-content-center item-center">
            <img
              src="../../assets/img/logoMarketplace.png"
              alt="logoMarketPlace"
              id="logoMarketPlaceText"
              style={{ marginTop: '2rem' }}
            />
          </div>
        </div>
      </section>
      <section
        id="contact"
        style={{
          backgroundColor: '#17181d',
          height: 'auto',
          marginTop: 'auto',
        }}
      >
        <ContactUs />
        <div className="container">
          <div className="row mt-5">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <h4
                className="text-white"
                style={{ margin: 'auto', fontSize: '1rem' }}
                id="text-contact"
              >
                INFO@HABITATVENEZUELA.COM
              </h4>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '2rem',
              }}
            >
              <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <FaInstagram id="igIcon" />
              </div>
              <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                <RiFacebookCircleLine id="FbIcon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
