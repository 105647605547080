import React from 'react';
import {
  CardProfile,
  EditProfile,
  InfoExtra,
  Spinner,
  CreditCard,
  UserLayout,
} from '../..';
import { useUser } from '../../../hooks';

export default function UserProfile() {
  const [user] = useUser();
  return (
    <UserLayout>
      {user ? (
        <div className="row">
          <div className="col-lg-4 col-md-5">
            <CardProfile user={user} />
          </div>
          <div className="col-lg-8 col-md-7">
            <EditProfile />
          </div>
          {user?.answer ?? '' ? null : (
            <div className="col-lg-12 col-md-8">
              <InfoExtra />
            </div>
          )}
          <div className="col-md-4 col-sm-4">
            <CreditCard
              name={user?.firstName}
              lastName={user?.firstLastname}
              email={user?.email}
            />
          </div>
        </div>
      ) : (
        <div className="container" style={{ height: '100vh' }}>
          <div className="row justify-content-center">
            <div style={{ position: 'relative', top: '300px' }}>
              <Spinner text="Cargando..." />
            </div>
          </div>
        </div>
      )}
    </UserLayout>
  );
}
